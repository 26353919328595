<template>
  <div ref="charts" style="width: 353px; height: 170px;  position: relative; z-index: 999;"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  mounted () {
    this.initEchats()
  },
  methods: {
    initEchats () {
      const charts = this.$refs.charts
      const myChart = echarts.init(charts)

      const option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)',
          position: function (p) {
            // 其中p为当前鼠标的位置
            return [p[0] + 10, p[1] - 10]
          }
        },
        legend: {
          top: '90%',
          itemWidth: 10,
          itemHeight: 10,
          data: ['前端', '考公', '考研', '后端', '出国留学', '嵌入式'],
          textStyle: {
            color: 'rgba(255,255,255,.5)',
            fontSize: '12'
          }
        },
        series: [
          {
            name: '研究方向',
            type: 'pie',
            center: ['50%', '42%'],
            radius: ['40%', '60%'],
            color: [
              '#065aab',
              '#066eab',
              '#0682ab',
              '#0696ab',
              '#06a0ab',
              '#06b4ab',
              '#06c8ab',
              '#06dcab',
              '#06f0ab'
            ],
            label: { show: false },
            labelLine: { show: false },
            data: [
              { value: 1, name: '前端' },
              { value: 4, name: '考公' },
              { value: 2, name: '考研' },
              { value: 2, name: '后端' },
              { value: 2, name: '嵌入式' },
              { value: 0.34, name: '出国留学' }
            ]
          }
        ]
      }

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option)
    }
  }
}
</script>

<style>

</style>
