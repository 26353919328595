import LayoutContainer from '@/views/layout/LayoutContainer.vue'
import Login from '@/views/login'
import ArticleMange from '@/views/article/ArticleMange.vue'
import TeacherDetail from '@/views/article/teacherDetail.vue' // 注意首字母大写
import UserProfile from '@/views/user/UserProfile.vue'
import NotFound from '@/views/404.vue'
// {
//     path: '/404',
//     component: NotFound
//   },
//   {
//     path: '*',
//     redirect: '/404'
//   }

const constanyRouter = [
  {
    path: '/login',
    hidden: true,
    component: Login
  },
  {
    path: '/',
    component: LayoutContainer,
    redirect: '/article/home',
    children: [
      {
        path: '/article/home',
        icon: 'el-icon-s-home',
        name: '首页',
        component: ArticleMange
      },
      {
        path: '/user/profile',
        name: '数据大屏',
        icon: 'el-icon-s-platform',
        component: UserProfile
      }, {
        path: '/teacher/detail/:teacher_id?', // 去掉斜杠
        hidden: true,
        name: '员工详情',
        component: TeacherDetail,
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '/404',
    hidden: true,
    component: NotFound
  }
]

export default constanyRouter
