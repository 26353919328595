import request from '@/utils/request'

// 获取学生列表
export function getListAPI (params) {
  return request({
    url: '/api/stu/list',
    params // 地址参数 查询参数
  })
}

// 增加学生列表
export function addStuAPI (obj) {
  return request.post('/api/stu/create', obj)
}

// 编辑学生列表
export function editStuAPI (data) {
  return request.post(`/api/stu/alter?stu_id=${data.stu_id}`, data)
}

// 删除学生列表
export function deleteStuAPI (data) {
  return request.post(`/api/stu/delete?stu_id=${data.lists}`, JSON.stringify(data))
}
