import request from '@/utils/request'

// 获取教师列表
export function getTeacherListAPI (params) {
  return request({
    url: '/api/teacher/list',
    params
  })
}

// 增加教师列表
export function addTeacherAPI (data) {
  return request.post('/api/teacher/create', data)
}

// 更新老师数据
export function updateTeacherAPI (data) {
  return request.post(`/api/teacher/alter?teacher_id=${data.teacher_id}`, data)
}

// 删除
export function deleteTeacherAPI (obj) {
  return request.post(`/api/teacher/delete?teacher_id=${obj.teacher_id}`, obj)
}
