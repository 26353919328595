import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import constanyRouter from './constanyRouter'
import asyncRoutes from './asyncRouter'
import store from '@/store'

Vue.use(VueRouter)

// 创建路由实例的工厂函数
const createRouter = () => new VueRouter({
  mode: 'history',
  routes: constanyRouter
})

const router = createRouter()
// 重置路由
export async function resetRouter () {
  // 重新创建路由器实例
  const newRouter = createRouter()

  // 替换当前的路由器实例
  router.matcher = newRouter.matcher // 重置matcher
  router.options = newRouter.options // 重置options
  localStorage.removeItem('token')
  store.commit('user/getUserInfo', {})
}

router.beforeEach(async (to, from, next) => {
  // const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : ''
  if (!token && to.path !== '/login') {
    next('/login') // 如果需要认证且没有token，则重定向到登录页
    return
  }
  NProgress.start() // 开始进度条

  if (!store.getters.userName && token) {
    next()
    const { data } = await store.dispatch('user/getListInfo')
    const userRouter = JSON.parse(data.Info.privilege_name || '[]')
    // console.log(asyncRoutes[0].children)

    // 拿到与路由权限相同的路由
    const filterRouter = asyncRoutes[0].children.filter(item => {
      return userRouter.includes(item.name)
    })
    // // router.addRoute(filterRouter)
    const dynamicRoutes = {
      path: '/',
      component: asyncRoutes[0].component, // 这里使用 LayoutContainer 组件
      children: [...filterRouter, { path: '*', redirect: '/404' }]
    }
    router.addRoute(dynamicRoutes)
    store.commit('user/setRoutes', filterRouter)
    // // console.log(dynamicRoutes)
    next(to.path)
  } else {
    next() // 否则，继续导航
  }
})

router.afterEach(() => {
  NProgress.done() // 结束进度条
})

export default router
