<template>
  <div>
    <div class="addButton">
    <el-button type="primary" size="mini" @click="isDialog = true">添加教师</el-button>
  </div>
  <!-- 表格 -->
  <template>
    <el-table
      :data="list" style="width: 100%;">
      <el-table-column label="执教时间" prop="datetime" align="center"  width="200">
      </el-table-column>
      <el-table-column label="教学班级" prop="class_name" align="center" width="200">
      </el-table-column>
      <el-table-column label="教师姓名" prop="name" align="center" width="190">
      </el-table-column>
      <el-table-column label="教学科目" prop="profession" align="center"  width="200">
      </el-table-column>
      <el-table-column label="教师性别" prop="sex" align="center" width="180">

      </el-table-column>
      <el-table-column label="教师电话" prop="tel" align="center" width="190">

      </el-table-column>
      <el-table-column label="操作" width="170" align="center">
        <template v-slot="{ row }">
            <el-button size="mini" type="text" @click="$router.push(`/teacher/detail/${row.teacher_id}`)">查看</el-button>
            <el-popconfirm title="这是一段内容确定删除吗？" @confirm="confirmDel(row)">
              <el-button slot="reference" style="margin-left:10px" size="mini" type="text">删除</el-button>
            </el-popconfirm>
          </template>
      </el-table-column>
    </el-table>
  </template>

    <!-- 放置dialog 弹出层-->
  <el-dialog append-to-body width="600px" title="新增教师" :close-on-click-modal="false" :visible.sync="isDialog" @close="close">
    <!-- 放置表单 -->
        <el-form label-width="90px" :model="formData" :rules="rules" ref="formData">
          <el-form-item label="教师姓名" prop="name">
            <el-input size="small"  v-model="formData.name"/>
          </el-form-item>
          <el-form-item label="教师性别" prop="sex">
            <el-input size="small" v-model="formData.sex"/>
          </el-form-item>
          <el-form-item label="教学班级" prop="class_name">
            <el-input  size="small" v-model="formData.class_name"/>
          </el-form-item>
          <el-form-item label="教学科目" prop="profession">
            <el-input size="small" v-model="formData.profession"/>
          </el-form-item>
          <el-form-item label="教师工号" prop="teacher_id">
            <el-input size="small" v-model="formData.teacher_id"/>
          </el-form-item>
          <el-form-item label="教师电话" prop="tel">
            <el-input size="small" v-model="formData.tel"/>
          </el-form-item>
          <el-form-item label="执教时间" prop="datetime">
            <el-input size="small" v-model="formData.datetime"/>
          </el-form-item>
          <el-form-item>
            <el-button size="mini" @click="isDialog = false">取消</el-button>
            <el-button size="mini" type="primary"  @click="btnOK">确定</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>

  </div>
</template>

<script>
import { getTeacherListAPI, addTeacherAPI, deleteTeacherAPI } from '@/api/teacherlist'
import { Message } from 'element-ui'
export default {
  data () {
    return {
      list: [],
      isDialog: false,
      formData: {
        name: '',
        class_name: '',
        teacher_id: '',
        sex: '',
        age: '97',
        tel: '',
        datetime: '',
        profession: '',
        isEdit: false
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { pattern: /^[\u4e00-\u9fa5]+$/, message: '只能输入中文', trigger: 'blur' }
        ],
        class_name: [
          { required: true, message: '请输入教学班级', trigger: 'blur' }
        ],
        teacher_id: [
          { required: true, message: '请输入教师工号', trigger: 'blur' },
          { pattern: /^\d+$/, message: '只能输入数字', trigger: 'blur' }
        ],
        sex: [
          { required: true, message: '请输入性别', trigger: 'blur' },
          { pattern: /^(男|女)$/, message: '只能输男或女', trigger: 'blur' }
        ],
        tel: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
        ],
        profession: [
          { required: true, message: '请输入教学科目', trigger: 'blur' }
        ],
        datetime: [
          { required: true, message: '请输入时间', trigger: 'blur' },
          { pattern: /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/, message: '日期格式不正确, 如2022-09-01 10:00:00', trigger: 'blur' }
        ]
      }
    }
  },

  mounted () {
    this.getTeaList()
  },

  methods: {
    // 获取教师数据
    async getTeaList () {
      const res = await getTeacherListAPI()
      this.list = res.data?.lists?.map(item => ({ ...item, datetime: item.datetime.replace(/T/g, ' ').replace(/\.\d+$/, '') }))
    },

    // 增加教师
    btnOK () {
      this.$refs.formData.validate(async isOk => {
        if (isOk) {
          const res = await addTeacherAPI(this.formData)
          if (res.code === 0) {
            this.isDialog = false
            Message.success('添加成功')
            this.getTeaList()
            console.log(res)
          }
        }
      })
    },
    close () {
      this.formData = {
        name: '',
        class_name: '',
        stu_id: '',
        sex: '',
        age: 20,
        tel: '',
        datetime: ''
      }
      this.$refs.formData.resetFields()
    },
    async confirmDel (row) {
      await deleteTeacherAPI(row)
      this.getTeaList()
      Message.success('删除成功')
    }
  }
}
</script>

<style lang="less" scoped>
  .addButton {
      margin-bottom: 10px;
      display: flex;
    }
</style>
