<template>
    <div>
      <div class="addButton">
      <el-button type="primary" size="mini" @click="isDialog = true">添加学生</el-button>
      <div class="inputModel">
        <el-input @input="changeValue"  style="width: 250px" clearable v-model="listData.query" placeholder="请输入内容" size="small "></el-input>
      </div>
    </div>
    <!-- 表格 -->
    <template>
      <el-table
        :data="list" style="width: 100%;">
        <el-table-column label="到校日期" prop="datetime" align="center"  width="200">
          <template v-slot="{ row }">
              <el-input v-if="row.isEdit" v-model="row.editRow.datetime" size="mini"></el-input>
              <span v-else> {{ row.datetime }} </span>
            </template>
        </el-table-column>
        <el-table-column label="班级" prop="class_name" align="center" width="200">
          <template v-slot="{ row }">
              <el-input v-if="row.isEdit" v-model="row.editRow.class_name" size="mini"></el-input>
              <span v-else> {{ row.class_name }} </span>
            </template>
        </el-table-column>
        <el-table-column label="姓名" prop="name" align="center" width="190">
          <template v-slot="{ row }">
              <el-input v-if="row.isEdit" v-model="row.editRow.name" size="mini"></el-input>
              <span v-else> {{ row.name }} </span>
            </template>
        </el-table-column>
        <el-table-column label="学号" prop="stu_id" align="center"  width="200">
          <template v-slot="{ row }">
              <el-input v-if="row.isEdit" v-model="row.editRow.stu_id" size="mini"></el-input>
              <span v-else> {{ row.stu_id }} </span>
            </template>
        </el-table-column>
        <el-table-column label="性别" prop="sex" align="center" width="180">
          <template v-slot="{ row }">
              <el-input v-if="row.isEdit" v-model="row.editRow.sex" size="mini"></el-input>
              <span v-else> {{ row.sex }} </span>
          </template>
        </el-table-column>
        <el-table-column label="电话" prop="tel" align="center" width="190">
          <template v-slot="{ row }">
              <el-input v-if="row.isEdit" v-model="row.editRow.tel" size="mini"></el-input>
              <span v-else> {{ row.tel }} </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="170" align="center">
          <template v-slot="{ row }">
            <template v-if="row.isEdit">
              <!-- 编辑状态 -->
              <el-button type="primary" size="mini" @click="btnEditOK(row)">确定</el-button>
              <el-button size="mini" @click="row.isEdit = false">取消</el-button>
            </template>
              <!-- 非编辑状态 -->
            <template v-else>
              <el-button size="mini" type="text" @click="handleEdit(row)">编辑</el-button>
              <el-popconfirm title="这是一段内容确定删除吗？" @confirm="confirmDel(row)">
                <el-button slot="reference" style="margin-left:10px" size="mini" type="text">删除</el-button>
              </el-popconfirm>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <!-- 分页器 -->
    <div class="page">
      <el-pagination
        @current-change="changePage"
        background
        :page-size="listData.page_size"
        layout="prev, pager, next"
        :page-count="totalPages"
        >
      </el-pagination>
    </div>

      <!-- 放置dialog 弹出层-->
    <el-dialog append-to-body width="600px" title="新增学生" :close-on-click-modal="false" :visible.sync="isDialog" @close="close">
      <!-- 放置表单 -->
          <el-form label-width="90px" :rules="rules" ref="formData" :model="formData">
            <el-form-item label="姓名" prop="name">
              <el-input v-model="formData.name" size="small" />
            </el-form-item>
            <el-form-item label="班级" prop="class_name">
              <template>
                <el-select v-model="formData.class_name" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label">
                </el-option>
              </el-select>
              </template>
              <!-- <el-input v-model="formData.class_name" size="small" /> -->
            </el-form-item>
            <el-form-item label="学号" prop="stu_id">
              <el-input v-model="formData.stu_id" size="small" />
            </el-form-item>
            <el-form-item label="性别" prop="sex">
              <el-input v-model="formData.sex" size="small" />
            </el-form-item>
            <el-form-item label="电话" prop="tel">
              <el-input v-model="formData.tel" size="small" />
            </el-form-item>
            <el-form-item label="入学时间" prop="datetime">
              <template>
                <div class="block">
                  <span class="demonstration"></span>
                  <el-date-picker
                    v-model="formData.datetime"
                    type="datetime"
                    placeholder="选择日期时间">
                  </el-date-picker>
                </div>
              </template>
              <!-- <el-input v-model="formData.datetime" size="small" /> -->
            </el-form-item>
            <el-form-item>
              <el-button size="mini" @click="isDialog = false">取消</el-button>
              <el-button size="mini" type="primary" @click="btnOK">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    </div>
</template>

<script>
import { getListAPI, addStuAPI, editStuAPI, deleteStuAPI } from '@/api/list'
import { Message } from 'element-ui'
export default {
  data () {
    return {
      list: [],
      // 存储查询参数
      listData: {
        page: 1,
        page_size: null,
        total: null,
        query: ''
      },
      isDialog: false,
      options: [{
        value: '选项1',
        label: '1班'
      }, {
        value: '选项2',
        label: '2班'
      }, {
        value: '选项3',
        label: '3班'
      }, {
        value: '选项4',
        label: '4班'
      }],
      formData: {
        name: '',
        class_name: '',
        stu_id: '',
        sex: '',
        age: 97,
        tel: '',
        datetime: '',
        isEdit: false
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { pattern: /^[\u4e00-\u9fa5]+$/, message: '只能输入中文', trigger: 'blur' }
        ],
        class_name: [
          { required: true, message: '请输入班级', trigger: 'blur' }
        ],
        stu_id: [
          { required: true, message: '请输入学号', trigger: 'blur' },
          { pattern: /^\d+$/, message: '只能输入数字', trigger: 'blur' }
        ],
        sex: [
          { required: true, message: '请输入性别', trigger: 'blur' },
          { pattern: /^(男|女)$/, message: '只能输男或女', trigger: 'blur' }
        ],
        tel: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
        ],
        datetime: [
          { required: true, message: '请输入时间', trigger: 'blur' }
        ]
      }
    }
  },

  mounted () {
    this.getList()
  },
  // 分页器总分页
  computed: {
    totalPages () {
      return Math.ceil(this.listData.total / this.listData.page_size) || 1
    }
  },

  methods: {
    // 获取学生列表
    async getList () {
      const res = await getListAPI(this.listData)
      // console.log(res)
      this.list = res.data?.lists?.map(item => ({ ...item, datetime: item.datetime.replace(/T/g, ' ').replace(/\.\d+$/, '') }))
      this.list?.forEach(item => {
        // forEach动态添加的属性不具备响应式
        this.$set(item, 'isEdit', false)
        this.$set(item, 'editRow', {
          name: item.name,
          class_name: item.class_name,
          stu_id: item.stu_id,
          datetime: item.datetime,
          sex: item.sex,
          tel: item.tel,
          isDeleted: false,
          lists: []
        })
      })
      this.listData.page = res.data?.cur_page
      this.listData.total = res.data?.total
      this.listData.page_size = res.data?.page_size
    },

    // 增添学生
    btnOK () {
      this.$refs.formData.validate(async isOk => {
        if (isOk) {
          const res = await addStuAPI(this.formData)
          console.log(this.formData.class_name)

          if (res.code === 0) {
            this.isDialog = false
            Message.success('添加成功')
            this.getList()
          }
        }
      })
    },

    // 编辑
    handleEdit (row) {
      row.isEdit = true
      row.editRow.name = row.name
      row.editRow.class_name = row.class_name
      row.editRow.tel = row.tel
      row.editRow.datetime = row.datetime
      row.editRow.sex = row.sex
      row.editRow.stu_id = row.stu_id
    },

    // 确定
    async btnEditOK (row) {
      if (row.name && row.class_name && row.stu_id && row.sex && row.tel) {
        await editStuAPI(row.editRow)
        row.name = row.editRow.name
        row.class_name = row.editRow.class_name
        row.tel = row.editRow.tel
        row.datetime = row.editRow.datetime
        row.sex = row.editRow.sex
        row.stu_id = row.editRow.stu_id
        row.isEdit = false
        Message({ type: 'success', message: '修改成功' })
      } else {
        Message({ type: 'error', message: '输入框不能为空' })
      }
    },

    // 删除
    async confirmDel (row) {
      const obj = row.editRow
      await deleteStuAPI(obj)
      console.log(row)

      if (this.list.length === 1 && this.listData.page > 1) this.listData.page--
      console.log(this.list.length)
      Message.success('删除成功')
      this.getList()
    },

    // 获取下一页数据
    changePage (value) {
      if (value < Math.ceil(this.listData.total / this.listData.page_size) || value === Math.ceil(this.listData.total / this.listData.page_size)) {
        this.listData.page = value
        this.getList()
        console.log(1)
      }
    },

    // 搜索列表
    changeValue () {
      // console.log(value)
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.getList()
      }, 200)
    },

    // 关闭弹出层
    close () {
      this.formData = {
        name: '',
        class_name: '',
        stu_id: '',
        sex: '',
        age: 20,
        tel: '',
        datetime: ''
      }
      this.$refs.formData.resetFields()
    }
  }
}
</script>

<style lang="less" scoped>
    .addButton {
      margin-bottom: 10px;
      display: flex;

      justify-content: space-between;
    }
    .page{
      text-align: right; /* 将内容右对齐 */
      padding: 20px;
    }
</style>
