import axios from 'axios'
import { Message } from 'element-ui'
const instance = axios.create({
  timeout: 50000 // 响应时间
})

// 请求拦截器
instance.interceptors.request.use(function (config) {
  // 在发送请求之前做什么
  const token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : ' '
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}, (error) => {
  // 失败执行 promise
  return Promise.reject(error)
})
// 响应式拦截器
instance.interceptors.response.use(function (response) {
  if (response.data.code === 0) {
    // 对响应后的数据做些什么
    return response.data
  } else if (response.data.code === 3001) {
    Message({
      message: '登录失败,账号或密码错误',
      type: 'error'
    })
  } else if (response.data.code === 3006) {
    Message({
      message: 'token 已过期，请重新登录',
      type: 'warning',
      duration: 1500
    })
  } else {
    Message({
      message: response.data.message,
      type: 'warning',
      duration: 1500
    })
  }
  return response.data
}, function (error) {
  return Promise.reject(error)
})

export default instance
