import request from '@/utils/request'

export function getPermissListAPI () {
  return request.get('/api/list')
}

// 修改功能的接口
export function updatePermissAPI (obj) {
  return request.post(`/api/router?user_id=${obj.user_id}`, obj)
}
