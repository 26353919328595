<template>
  <div class="login-form">
    <el-card shadow="never" class="login-card">
      <div class="card-content">
        <h3>校园数据管理平台</h3>
        <el-form hide-required-asterisk style="width: 360px;" label-width="50px" :model="loginForm" :rules="rules" ref="loginForm">
          <el-form-item label="账户" prop="user_id">
            <el-input placeholder="请输入账号" v-model="loginForm.user_id"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input placeholder="请输入密码" v-model="loginForm.password" show-password></el-input>
          </el-form-item>
          <el-form-item prop="isAgree">
            <el-checkbox v-model="loginForm.isAgree">用户平台使用协议</el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" style="width: 280px;" @click="login">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getLogin } from '@/api/login'
import { Message } from 'element-ui'

export default {
  name: 'LoginIndex',
  data () {
    return {
      loginForm: {
        user_id: '',
        password: '',
        isAgree: false
      },
      rules: {
        user_id: [
          { required: true, message: '账号不能为空', trigger: 'blur' },
          { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          { min: 6, max: 16, message: '密码长度应该为6-16位之间', trigger: 'blur' }
        ],
        isAgree: [
          {
            validator: (rule, value, callback) => {
              value ? callback() : callback(new Error('您必须勾选用户的使用协议'))
            }
          }
        ]
      }
    }
  },
  methods: {
    login () {
      this.$refs.loginForm.validate(async (isOk) => {
        if (isOk) {
          const res = await getLogin(this.loginForm)
          if (res.code === 0) {
            localStorage.setItem('token', JSON.stringify(res.data?.token) || '')
            Message({ type: 'success', message: '登陆成功' })
            this.$router.push('/')
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.login-form {
  margin: 0;
  padding: 0;
  background-image: url(../../assets/login.webp);
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  font-family: Arial, sans-serif;
}

.login-card {
  background-color: rgba(30, 93, 173, 0.1) !important; /* 半透明的白色 */
  border: rgba(30, 93, 173, 0.5) 1px solid !important;
  animation: fadeIn 1.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

::v-deep .el-form-item__label {
  color: rgb(226, 224, 224);
}

.el-checkbox {
  color: rgb(226, 224, 224) !important;
}

h3 {
  color: #1b72dc;
  font-family: "宋体";
  font-size: 25px;
}

.main {
  transition: width 0.3s;
}

/* 按钮悬停效果 */
.el-button {
  transition: all 0.3s ease;
}

.el-button:hover {
  transform: scale(1.05);
  background-color: #1b72dc;
  border-color: #1b72dc;
}
</style>
