import {
  Button, Container, Aside, Main, Row, Col,
  Form, Input, FormItem, Card, Checkbox,
  Menu, MenuItem, Submenu, MenuItemGroup, Header,
  Table, TableColumn, Pagination, Dialog, Popconfirm,
  Dropdown, DropdownItem, DropdownMenu, CheckboxGroup,
  Calendar, DatePicker, Option, Select
} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

const element = {
  install: function (Vue) {
    Vue.use(Button)
    Vue.use(Container)
    Vue.use(Aside)
    Vue.use(Main)
    Vue.use(Row)
    Vue.use(Col)
    Vue.use(Form)
    Vue.use(Input)
    Vue.use(FormItem)
    Vue.use(Card)
    Vue.use(Checkbox)
    Vue.use(Menu)
    Vue.use(MenuItem)
    Vue.use(Submenu)
    Vue.use(MenuItemGroup)
    Vue.use(Header)
    Vue.use(Table)
    Vue.use(TableColumn)
    Vue.use(Pagination)
    Vue.use(Dialog)
    Vue.use(Popconfirm)
    Vue.use(Dropdown)
    Vue.use(DropdownItem)
    Vue.use(DropdownMenu)
    Vue.use(CheckboxGroup)
    Vue.use(Calendar)
    Vue.use(DatePicker)
    Vue.use(Option)
    Vue.use(Select)
  }
}

export default element
