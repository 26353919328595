<template>
  <div ref="chart" style="width: 353px; height: 170px; z-index: 999;"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  mounted () {
    this.initEcharts()
  },

  methods: {
    initEcharts () {
      const chart = this.$refs.chart
      const myChart = echarts.init(chart)

      const data = [70, 38, 60, 78, 69, 37]
      const titlename = ['HTML5', 'C语言', 'Java', 'VUE', 'Node', '其他']
      const valdata = [702, 350, 610, 793, 664, 337]
      const myColor = ['#1089E7', '#F57474', '#56D0E3', '#F8B448', '#8B78F6', '#26c4fe']
      const option = {
        // 图标位置
        grid: {
          top: '10%',
          left: '22%',
          bottom: '10%'
        },
        xAxis: {
          show: false
        },
        yAxis: [
          {
            show: true,
            data: titlename,
            // 逆序
            inverse: true,
            axisLine: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#fff',

              rich: {
                lg: {
                  backgroundColor: '#339911',
                  color: '#fff',
                  borderRadius: 15,
                  // padding: 5,
                  align: 'center',
                  width: 15,
                  height: 15
                }
              }
            }
          },
          {
            show: true,
            inverse: true,
            data: valdata,
            axisLabel: {
              textStyle: {
                fontSize: 12,
                color: '#fff'
              }
            }
          }
        ],
        series: [
          {
            name: '条',
            type: 'bar',
            yAxisIndex: 0,
            data: data,
            barCategoryGap: 50,
            barWidth: 10,
            itemStyle: {
              normal: {
                barBorderRadius: 20,
                color: function (params) {
                  const num = myColor.length
                  return myColor[params.dataIndex % num]
                }
              }
            },
            label: {
              normal: {
                show: true,
                position: 'inside',
                formatter: '{c}%'
              }
            }
          },
          {
            name: '框',
            type: 'bar',
            yAxisIndex: 1,
            barCategoryGap: 50,
            data: [100, 100, 100, 100, 100, 100],
            barWidth: 15,
            itemStyle: {
              normal: {
                color: 'none',
                borderColor: '#00c1de',
                borderWidth: 3,
                barBorderRadius: 15
              }
            }
          }
        ]
      }

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option)
    }
  }
}
</script>

<style>

</style>
