<template>
    <div>
        <div>
    <el-container class="home-container">
      <!-- 使用:class 绑定多个属性
      :class={small: isCollapse, big: bigCollapse}
      -->
      <!-- 使用:class 三元表达式绑定属性 -->
    <el-aside width="145px" :class="isCollapse ? 'small' : 'big'">
        <el-menu
        :collapse="isCollapse"
        :router="true"
        :default-active="changeRoute"
        background-color="#545c64"
        :collapse-transition="false"
        text-color="#fff"
        active-text-color="#fff"
        >
        <div class="logo">
          <img src="../../assets/logo.png">
        </div>
        <template v-for="item in routers">
        <el-menu-item v-if="!item.hidden" :key="item.path" :index="item.path">
            <i :class="item.icon"></i>
            <span>{{ item.name }}</span>
        </el-menu-item>
      </template>
        </el-menu>
        </el-aside>
        <el-container>
            <el-header style="height: 45px;">
              <div class="titleRouter">
                  <span class="iconfont icon-iccategory"  @click="tabCollapse"></span>
                  <span @click="clickHome" role="link" v-show="changeList !== '首页'"><a>Dashboard</a></span>
                  <span v-show="changeList !== '首页'">&nbsp;&nbsp;&nbsp;/</span>
                  <div class="listTitle">
                    <span v-for="(list, index) in changeList" :key="index">{{ list }}</span>
                  </div>
              </div>
            <!-- 头像 -->
            <div class="userbox">
            <el-dropdown trigger="click" placement="bottom">
            <div class="avatar">
              <img src="https://ts4.cn.mm.bing.net/th?id=OIP-C.Uoz1-5uTFTJ7s8fymTUhYgAAAA&w=250&h=250&c=8&rs=1&qlt=90&o=6&dpr=1.4&pid=3.1&rm=2">
            </div>
            <el-dropdown-menu slot="dropdown">
              <router-link to="/" style="text-decoration: none;">
            <el-dropdown-item>首页</el-dropdown-item>
            </router-link>
            <a href="https://gitee.com/Andyviva/vscode-school-managemen-tplatform.git" style="text-decoration: none; color: gray;">
              <el-dropdown-item>项目地址</el-dropdown-item></a>
            <el-dropdown-item>修改密码</el-dropdown-item>
            <!-- .native：这是一个修饰符，用于在自定义组件上绑定原生 DOM 事件。当使用 .native 修饰符时，Vue 会将事件监听器绑定到组件的根元素上 -->
            <el-dropdown-item  @click.native="logout" >
              <span>退出登录</span>
            </el-dropdown-item>
          </el-dropdown-menu>
          </el-dropdown>
            <!-- 用户名 -->
            <span>{{ userName }}</span>
          </div>
          </el-header>
            <el-main class="main">
            <!-- 二级路由出口 -->
            <router-view></router-view>
            </el-main>
        </el-container>
        </el-container>
     </div>
    </div>
</template>

<script>

import { remove } from '@/utils/localStorage'
import { mapGetters } from 'vuex'
export default {
  name: 'LayoutContainer',
  data () {
    return {
      isCollapse: false,
      list: ''
    }
  },

  computed: {
    ...mapGetters([
      'userName', 'routers'
    ]),
    changeList () {
      return this.$route.name
    },
    changeRoute () {
      return this.$route.path
    }
  },
  methods: {
    tabCollapse () {
      this.isCollapse = !this.isCollapse
      console.log(this.$router.options.routes[1].children)
    },
    clickHome () {
      if (this.$route.path !== '/article/home') {
        this.$router.push('/article/home')
      }
    },
    logout () {
      remove()
      // this.$store.commit('user/setRoutes', [])
      this.$router.push('/login')
    }
  }
}
</script>

<style  lang="less" scoped>
 .el-header{
    color: #333;
    background-color:white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px; /* 可以根据需要调整内边距 */
  }

  .el-aside {
    background-color: rgb(84, 92, 100);
    color: #333;
    line-height: 200px;
  }

   .el-main {
    background-color: #E9EEF3;
    color: #333;
    transition: width 0.3s;
    box-shadow: inset 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  }

  body > .el-container {
    margin-bottom: 40px;
  }

  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }

  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }
  .home-container{
    height: 100vh;
  }
  .el-menu{
    border-right: solid 0px #e6e6e6 !important;
    padding-left: 0px !important;
  }
  .iconfont{
    font-size: 20px;
  }
  .small{
    width: 70px !important;
    transition: 0.3s;

    .logo{
      display: flex;
      img{
        width: 64px;
        height: 56px;
      }
    }
  }
  .big{
    transition: 0.3s;
    .logo{
      display: flex;
      img{
        width: 145px;
        height: 56px;
      }
    }
  }
  .userbox{
    width: 100px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 25px;
    span{
      text-align: center;
      padding-left: 15px;
      font-size: 15px;
    }
    .avatar {
      width: 35px; /* 头像的宽度 */
      height: 35px; /* 头像的高度 */
      border-radius: 50%; /* 使图片变成圆形 */
      overflow: hidden; /* 隐藏超出边界的部分内容 */
      border: 2px solid #ccc; /* 可选：添加边框 */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 可选：添加阴影效果 */

      img {
      width: 100%; /* 图片宽度占满整个容器 */
      height: 100%; /* 图片高度占满整个容器 */
      object-fit: cover; /* 保持图片的宽高比并覆盖整个容器 */
      cursor: pointer;
    }
   }

  }
  .titleRouter{
    width: 250px;
    display: flex;

   .listTitle{
      color: gray;
      font-size: 14px;
      padding-left: 13px;
    }
  }

  .titleRouter > span:nth-child(1){
     cursor: pointer;
   }

  .titleRouter > span:nth-child(2){
    font-size: 17px;
    font-weight: 530;
    padding-left: 13px;
    cursor: pointer;
   }

   .titleRouter > span:nth-child(2) > a:hover{
    color: rgba(96, 174, 255);
   }

   .titleRouter > span:nth-child(3){
    padding-top: 1px;
    color: #C0C4CC;
   }
</style>
