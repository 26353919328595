<template>
    <div>
        <div class="formTW">
        <el-form  label-width="120px"  :model="formData" :rules="rules" ref="formData">
            <el-form-item label="教师姓名" prop="name">
                <el-input size="mini" v-model="formData.name" class="inputW"/>
            </el-form-item>
            <el-form-item label="教师工号" prop="teacher_id">
                <el-input size="mini" v-model="formData.teacher_id" disabled/>
            </el-form-item>
            <el-form-item label="执教时间" prop="datetime">
                <el-input size="mini" v-model="formData.datetime" disabled/>
            </el-form-item>
            <el-form-item label="教师性别" prop="sex">
                <el-input size="mini" v-model="formData.sex"/>
            </el-form-item>
            <el-form-item label="教师年龄" prop="age">
                <el-input size="mini" v-model="formData.age"/>
            </el-form-item>
            <el-form-item label="教师班级" prop="class_name">
                <el-input size="mini" v-model="formData.class_name"/>
            </el-form-item>
            <el-form-item label="教师科目" prop="profession">
                <el-input size="mini" v-model="formData.profession"/>
            </el-form-item>
            <el-form-item label="教师电话" prop="tel">
                <el-input size="mini" v-model="formData.tel"/>
            </el-form-item>
            <el-row>
                <el-col :span="12" style="margin-left:220px">
                    <el-button @click=" $router.go(-1)" size="mini">取消更新</el-button>
            <el-button type="primary" size="mini" @click="updateTeacher">确认更新</el-button>
                </el-col>
            </el-row>
        </el-form>
    </div>
    </div>
</template>

<script>
import { updateTeacherAPI, getTeacherListAPI } from '@/api/teacherlist'
import { Message } from 'element-ui'
export default {
  data () {
    return {
      formData: {
        name: '',
        class_name: '',
        teacher_id: '',
        sex: '',
        age: '',
        tel: '',
        datetime: '',
        profession: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { pattern: /^[\u4e00-\u9fa5]+$/, message: '只能输入中文', trigger: 'blur' }
        ],
        class_name: [
          { required: true, message: '请输入教学班级', trigger: 'blur' }
        ],
        teacher_id: [
          { required: true, message: '请输入教师工号', trigger: 'blur' },
          { pattern: /^\d+$/, message: '只能输入数字', trigger: 'blur' }
        ],
        sex: [
          { required: true, message: '请输入性别', trigger: 'blur' },
          { pattern: /^(男|女)$/, message: '只能输男或女', trigger: 'blur' }
        ],
        age: [
          { required: true, message: '请输入年龄', trigger: 'blur' },
          { pattern: /^\d+$/, message: '只能输入数字', trigger: 'blur' }
        ],
        tel: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
        ],
        profession: [
          { required: true, message: '请输入教学科目', trigger: 'blur' }
        ],
        datetime: [
          { required: true, message: '请输入时间', trigger: 'blur' },
          { pattern: /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/, message: '日期格式不正确, 如2022-09-01 10:00:00', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    async getList () {
      const res = await getTeacherListAPI(this.$route.params)
      this.formData = res.data.lists.map(item => ({ ...item, datetime: item.datetime.replace(/T/g, ' ').replace(/\.\d+$/, ' ') }))[0]
    },
    // 修改
    async updateTeacher () {
      this.$refs.formData.validate(async isOk => {
        if (isOk) {
          this.formData.age = this.formData.age.toString()
          const res = await updateTeacherAPI(this.formData)
          if (res.code === 0) {
            Message.success('修改成功')
            this.$router.go(-1)
          }
        }
      })
    }
  }
}
</script>

<style>

    .formTW{
        width: 400px;
        padding: 40px 0 0 80px;
    }
    .inputW{
        width: 380px;
    }
</style>
