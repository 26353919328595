<template>
  <div class="maxBox">
    <!-- 左边盒子 -->
    <div class="leftBox">
      <div class="leftitem">
        <div class="userInfo">
          <div class="log">
            <img src="../../assets/log.jpg">
            <div class="titleBox">
              <div class="titleItme">赣南医科大学校园信息网
                <span class="spanTitle">体验版</span>
              </div>
              <div class="titleInfo">校园网 | 信息墙</div>
            </div>
          </div>
        </div>
        <!-- 数据 -->
        <div class="todoList">
          <div class="todoItme" v-for="(item, index) in todoItems" :key="index">
            <span>{{ item.label }}</span>
            <count-to
              :start-val="0"
              :end-val="item.value"
              :duration="1000"
            ></count-to>
          </div>
        </div>
      </div>
      <!-- 快捷入口 -->
      <div class="leftmiddle">
        <div class="quickTitle">快捷入口</div>
        <div class="quickImg">
          <div class="qiuckitem" v-for="(item, index) in quickItems" :key="index" :class="{ 'first-item': index === 0 }">
            <a href="https://www.gmu.cn/xwzx/gyyw/164.htm"><div class="icon" :class="item.iconClass"></div></a>
            <span class="iconTitle">{{ item.label }}</span>
          </div>
        </div>
      </div>
      <div class="leftFooter">
        <!-- 图表数据 -->
         <div class="chart-container">
           <div class="chart-info">
             <div class="bookData">图书馆查阅数据</div>
             <div class="chart-main">
              <span>纸质藏书数量</span>
              <count-to
              :start-val="0"
              :end-val="1430000"
              :duration="1000"
            ></count-to>
            </div>
            <div class="chart-list">
              <div class="chart-list-item">
                <span>正在查阅(人)</span>
                <count-to
                  :start-val="0"
                  :end-val="128"
                  :duration="1000"
                ></count-to>
              </div>
              <div class="chart-list-item">
                <span>已查阅(人)</span>
                <count-to
                  :start-val="0"
                  :end-val="165"
                  :duration="1000"
                ></count-to>
              </div>
              <div class="chart-list-item">
                <span>已借阅(人)</span>
                <count-to
                  :start-val="0"
                  :end-val="138"
                  :duration="1000"
                ></count-to>
              </div>
            </div>
          </div>
           <div ref="chart" style="width: 500px; height: 300px; margin: 30px"></div>
         </div>
      </div>
    </div>

    <!-- 右边盒子 -->
    <div class="rightBox">
      <div class="rightItme">
        <div class="help-left">
          <div class="helptitel">
            帮助链接
          </div>
          <div class="help-list">
                <div class="help-block">
                  <i class="icon-help" />
                  在线帮助手册
                </div>
                <div class="help-block">
                  <i class="icon-entry" />
                  入门指南
                </div>
                <div class="help-block">
                  <i class="icon-support" />
                  联系技术支持
                </div>
                <div class="help-block">
                  <i class="icon-add" />
                  添加链接
                </div>
              </div>
        </div>
        <div class="help-right">
          <div class="help-right">
              <div class="calendar">
                <!-- <el-calendar /> -->
                <el-calendar />
              </div>
              </div>
        </div>
      </div>
      <div class="rightfooter">
        <div class="panelTitle">通知公告</div>
        <div class="informationList">
          <div class="informationListItem">
            <img src="https://heimahr.itheima.net/static/img/img.0615818f.jpeg" alt="">
            <p><a href="https://www.gmu.cn/info/1003/14809.htm">我校柔性引进国家级人才尹芝南教授并举行聘任仪式。</a>
              <span>2024-11-21</span>
            </p>

          </div>
          <div class="informationListItem">
            <img src="https://heimahr.itheima.net/static/img/img.0615818f.jpeg" alt="">
            <p><a href="https://www.gmu.cn/info/1008/14639.htm">中共赣南医科大学委员会关于十五届省委第四轮巡视整改进展情况</a>
              <span>2024-10-21</span>
            </p>

          </div>
          <div class="informationListItem">
            <img src="https://heimahr.itheima.net/static/img/img.0615818f.jpeg" alt="">
            <p><a href="https://www.gmu.cn/info/1008/14559.htm">关于启用“赣南医科大学学生证专用章” 印章及钢印的公告</a>
              <span>2024-09-29</span>
            </p>

          </div>
          <div class="informationListItem">
            <img src="https://heimahr.itheima.net/static/img/img.0615818f.jpeg" alt="">
            <p><a href="https://www.gmu.cn/info/1008/14483.htm">赣南医科大学新闻发言人名单</a>
              <span>2024-09-13</span>
            </p>
          </div>

          <div class="informationListItem">
            <img src="https://heimahr.itheima.net/static/img/img.0615818f.jpeg" alt="">
            <p><a href="https://www.gmu.cn/info/1003/14496.htm">我校召开临床医学专业认证工作第三次推进会</a>
              <span>2024-09-13</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountTo from 'vue-count-to'
import * as echarts from 'echarts'
export default {
  components: {
    CountTo
  },
  data () {
    return {
      todoItems: [
        { label: '校内总人数', value: 13500 },
        { label: '研究生', value: 300 },
        { label: '博士生', value: 30 },
        { label: '校长信箱', value: 13 },
        { label: '春季招聘会', value: 135 },
        { label: '校运会', value: 349 },
        { label: '喜迎校庆', value: 130 }
      ],
      quickItems: [
        { label: '假期审批', iconClass: 'approval' },
        { label: '讲座查询', iconClass: 'social' },
        { label: '角色管理', iconClass: 'role' },
        { label: '医保管理', iconClass: 'salary' },
        { label: '流程查询', iconClass: 'bpm' }
      ]
    }
  },

  mounted () {
    this.initChart()
  },
  methods: {
    initChart () {
      // 基于准备好的dom，初始化echarts实例
      const chart = this.$refs.chart
      const myChart = echarts.init(chart)

      // 指定图表的配置项和数据
      const option = {

        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['周一', '周二', '周三', '周四', '周五', '周六', '周天']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'line',
            areaStyle: {}
          }
        ]
      }

      // 使用刚指定的配置项和数据显示图表
      myChart.setOption(option)
    }
  }
}
</script>

<style lang="less" scoped>
.maxBox {
  display: flex;
  justify-content: space-around;

  .leftBox {
    .leftitem {
      width: 780px;
      height: 300px;
      background-color: white;

      .userInfo {
        .log {
          display: flex;
          padding: 20px;

          img {
            width: 50px;
            height: 50px;
            border-radius: 15px;
            border: 1px solid #d9d9d9;
          }

          .titleBox {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 7px;

            .titleItme {
              color: #383c4e;
            }

            .spanTitle {
              font-size: 12px;
              background: #f5f6f8;
              text-align: center;
              padding: 2px 8px;
              border-radius: 2px;
              color: #697086;
            }

            .titleInfo {
              font-size: 14px;
              color: #697086;
              font-weight: 400;
            }
          }
        }
      }

      .todoList {
        display: flex;
        flex-wrap: wrap;
        padding: 5px 0 0 20px;

        .todoItme {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 18%;
          height: 70px;
          padding: 10px;

          span:nth-child(1) {
            color: #697086;
            font-size: 17px;
          }

          span:nth-child(2) {
            color: #383c4e;
            font-size: 30px;
            font-weight: 500;
          }
        }
      }
    }

    .leftmiddle {
      background-color: white;
      width: 780px;
      height: 150px;
      margin-top: 8px;

      .quickTitle {
        padding: 20px 0 0 20px;
      }

      .quickImg {
        display: flex;

        .qiuckitem {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-left: 20px;
          margin: 20px 0 0 50px;

          &.first-item {
            margin-left: 0px; /* 第一个图标的左边距为0 */
          }

          .icon {
            width: 40px;
            height: 40px;
            border-radius: 10px;
            background: #f5f6f8;
            background-size: cover;
          }

          .approval {
            background-image: url('../../assets/approval.png');
          }

          .social {
            background-image: url('../../assets/social.png');
          }

          .role {
            background-image: url('../../assets/role.png');
          }

          .salary {
            background-image: url('../../assets/salary.png');
          }

          .bpm {
            background-image: url('../../assets/bpm.png');
          }

          .iconTitle {
            color: #697086;
            font-size: 14px;
            margin-top: 8px;
          }
        }
      }
    }

    .leftFooter {
      background-color: white;
      width: 780px;
      height: 380px;
      margin-top: 8px;
      .chart-container{
        display: flex;
        margin-top: 8px;
        padding: 20px;
        .chart-info{
          .bookData{
          display: flex;
          width: 200px;
          font-size: 16px;
          color: #383c4e;
          font-weight: 500;

        }
        .chart-main{
          display: flex;
          flex-direction: column;
          margin: 20px;
          text-align:center;

          span:nth-child(1){
            font-size: 14px;
            color: #697086;
          }
          span:nth-child(2){
            margin-top: 10px;
            font-size: 30px;
            color: #04c9be;
            font-weight: 500;
          }
        }
        .chart-list{
          background: #f5f6f8;
          border-radius: 4px;
          padding: 12px 15px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          .chart-list-item{
            display: flex;
            flex-direction: column;
            padding: 10px;

            span:nth-child(1){
              font-size: 14px;
              color: #697086;
            }

            span:nth-child(2){
              margin-top: 10px;
              font-size: 30px;
              color: #383c4e;
              font-weight: 500;
            }
          }
        }
      }
      }

    }
  }

  .rightBox {
    margin-left: 8px;
    width: 100%;
    .rightItme {
      width: 95%;
      height: 330px;
      background-color: white;
      display: flex;
      padding: 20px;

      .help-list {
          .help-block {
            background: #f5f6f8;
            border-radius: 4px;
            width: 164px;
            padding: 17px 10px;
            font-size: 14px;
            color: #697086;
            margin-top: 10px;
            i {
              width: 14px;
              height: 14px;
              display: inline-block;
              background-size: cover;
              vertical-align: middle;
            }
            i.icon-help {
              background-image: url("../../assets/help.png");
            }
             i.icon-support {
              background-image: url("../../assets/support.png");
            }
             i.icon-add {
              background-image: url("../../assets/add.png");
            }
             i.icon-entry {
              background-image: url("../../assets/entry.png");
            }
          }
        }

        .help-right{
          flex: 1;
        }
        ::v-deep .el-calendar-day {
          height:  40px;
        }
        ::v-deep .el-calendar-table__row td,::v-deep .el-calendar-table tr td:first-child, ::v-deep .el-calendar-table__row td.prev{
          border:none;
        }
        ::v-deep .el-calendar-table td.is-selected .text{
          background: #409eff;
          color: #fff;
          border-radius: 50%;
        }
        ::v-deep .el-calendar__header {
          display: none
        }
    }

    .rightfooter {
      width: 102.3%;
      height: 468px;
      background-color: white;
      margin-top: 8px;

      .informationList {
        margin-top: 20px;
        .informationListItem {
          display: flex;
          align-items: center;
          margin:15px 0;
          p{
            a{
              color: inherit; /* 继承父元素的颜色 */
              text-decoration: none; /* 去掉下划线 */
            }
            span{
            display: block;
            color: #697086;
            font-size: 14px;
            padding-top: 5px;
          }
          }

          img {
            width: 40px;
            height: 40px;
            border: 50%;
          }
         .col {
           color: #8a97f8;
         }
         div :nth-child(2) {
          color: #697086;
          font-size: 14px;
         }
        }
      }
    }
  }
}
</style>
