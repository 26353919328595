import LayoutContainer from '@/views/layout/LayoutContainer.vue'
import ArticleChannel from '@/views/article/ArticleChannel.vue'
import AriticTeacher from '@/views/article/AriticTeacher.vue'
import UserPassword from '@/views/user/UserPassword.vue'

const asyncRoutes = [
  {
    path: '/',
    component: LayoutContainer,
    children: [
      {
        path: '/article/channel', // 去掉斜杠
        name: '学生管理',
        icon: 'el-icon-s-data',
        component: ArticleChannel,
        meta: { requiresAuth: true }
      },
      {
        path: '/article/teacher', // 去掉斜杠
        name: '教师管理',
        icon: 'el-icon-user-solid',
        component: AriticTeacher,
        meta: { requiresAuth: true }
      },
      {
        path: '/user/password', // 去掉斜杠
        name: '权限管理',
        icon: 'el-icon-lock',
        component: UserPassword,
        meta: { requiresAuth: true }
      }
    ]
  }
]

export default asyncRoutes
