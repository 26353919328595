<template>
  <div>
    <div>
      <el-table
      :data="list" style="width: 100%;">
      <el-table-column label="角色" prop="username" align="center"  width="200">
      </el-table-column>
      <el-table-column label="电话" prop="user_id" align="center" width="200">
      </el-table-column>
      <el-table-column label="描述" prop="privilege_name" align="center" width="210">
      </el-table-column>
      <el-table-column label="操作" width="170" align="center" >
        <template v-slot="{ row }">
            <el-button size="mini" type="text" @click="updatePermiss(row)">分配权限</el-button>
            <el-popconfirm title="这是一段内容确定删除吗？" @confirm="confirmDel(row)">
              <el-button slot="reference" style="margin-left:10px" size="mini" type="text">删除</el-button>
            </el-popconfirm>
          </template>
      </el-table-column>
    </el-table>
  </div>

  <!-- 弹出层 -->
  <el-dialog append-to-body width="600px" title="分配权限" :close-on-click-modal="false" :visible.sync="isDialog">
    <!-- 放置表单 v-slot="{ row }"-->
    <template>
      <el-checkbox-group  v-model="listIds">
        <el-checkbox v-for="item in list[0]?.privilege" :key="item" :label="item">{{ item }}</el-checkbox>
      </el-checkbox-group>
      <el-row slot="footer" type="flex" justify="center">
          <el-col :span="6">
            <el-button size="mini" @click="isDialog = false">取消</el-button>
            <el-button size="mini" type="primary" @click="btnOK">确定</el-button>
          </el-col>
        </el-row>
    </template>

  </el-dialog>
  </div>
</template>

<script>
import { getPermissListAPI, updatePermissAPI } from '@/api/Permissions'
import { Message } from 'element-ui'
export default {
  data () {
    return {
      list: [],
      isDialog: false,
      listIds: [],
      permiss: [],
      permissData: {
        user_id: '',
        privilege_name: []
      }
    }
  },
  mounted () {
    this.getPermissList()
  },
  methods: {
    async getPermissList () {
      const res = await getPermissListAPI()
      this.list = res.data?.lists
      // console.log(this.list)

      this.list?.forEach(item => {
        const premiss = JSON.parse(item.privilege_name)
        item.privilege = ['学生管理', '教师管理', '权限管理']
        item.privilege_name = premiss
      })
      const listSet = this.list?.map(item => item.privilege_name).flat()
      this.permiss = [...new Set(listSet)]
    },
    async updatePermiss (row) {
      this.isDialog = true
      // console.log(res)
      // console.log(row.privilege_name.filter(item => item !== this.list[0].privilege_name))
      const filterRes = row.privilege_name.filter(item => item !== this.list[1]?.privilege)

      this.listIds = filterRes
      this.permissData.user_id = row.user_id
    },
    // 确定修改
    async btnOK () {
      this.permissData.privilege_name = JSON.stringify(this.listIds).replace(/\\\\/g, '\\')
      // console.log(this.permissData)

      const res = await updatePermissAPI(this.permissData)
      if (res.code === 0) {
        this.getPermissList()
        Message.success('修改成功')
      }
      this.isDialog = false
    }
  }
}
</script>

<style>

</style>
