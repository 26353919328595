import request from '@/utils/request.js'

export function getLogin (data) {
  return request({
    url: '/api/login',
    method: 'post',
    data
  })
}

export function getListUserInfo (data) {
  return request({
    url: '/api/Info',
    method: 'post',
    data
  })
}
