<template>
    <div ref="chart" style="width: 353px; height: 170px; z-index: 999;"></div>
  </template>

<script>
import * as echarts from 'echarts'
export default {
  mounted () {
    this.initCharts()
  },
  methods: {
    initCharts () {
      const chart = this.$refs.chart
      const myChart = echarts.init(chart)
      const colors = [
        '#006cff',
        '#60cda0',
        '#ed8884',
        '#ff9f7f',
        '#0096ff',
        '#9fe6b8',
        '#32c5e9',
        '#1d9dff'
      ]
      const data = [
        { value: 20, name: '云南' },
        { value: 26, name: '北京' },
        { value: 24, name: '山东' },
        { value: 25, name: '河北' },
        { value: 20, name: '江苏' },
        { value: 25, name: '浙江' },
        { value: 30, name: '深圳' },
        { value: 42, name: '广东' }
      ]

      // 为每个数据项添加颜色
      data.forEach((item, index) => {
        // 给每一项数据饼图设置颜色
        item.itemStyle = { color: colors[index] }
        // 给每一项字体设置颜色
        item.label = {
          color: colors[index],
          fontSize: 10
        }
      })

      const option = {
        legend: {
          top: '90%',
          itemWidth: 10,
          itemHeight: 10,
          textStyle: {
            color: 'rgba(255,255,255,.5)',
            fontSize: '12'
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        series: [
          {
            name: '点位统计',
            type: 'pie',
            radius: ['10%', '70%'],
            center: ['50%', '42%'],
            roseType: 'radius',
            data: data,
            labelLine: {
              length: 10,
              length2: 10
            }
          }
        ]
      }

      // 3. 配置项和数据给我们的实例化对象
      myChart.setOption(option)
    }
  }
}
</script>

  <style>

  </style>
