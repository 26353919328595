import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
  getters: {
    token: state => state.user.token,
    userName: state => state.user.userInfo.username,
    // Router: state => JSON.parse(state.user.userInfo.privilege_name),
    routers: state => state.user.routes
  },
  modules: {
    user
  }
})
