import { getListUserInfo } from '@/api/login'
import constanyRouter from '@/router/constanyRouter'
export default {
  // 开启命名空间, 为了使不和其他模块之间的 action、mutation 和 getter 的命名冲突
  namespaced: true,
  state: {
    userInfo: {},
    routes: constanyRouter,
    isLoggedOut: false
  },
  // 进行异步操作
  actions: {
    async getListInfo (context) {
      const res = await getListUserInfo()
      context.commit('getUserInfo', res.data?.Info)
      return res
    }
  },

  mutations: {

    getUserInfo (state, obj) {
      state.userInfo = obj
    },
    setRoutes (state, newRouter) {
      // 返回静态路由加上动态路由
      state.routes = [...constanyRouter[1].children, ...newRouter]
    },
    setLoggedOut (state, value) {
      state.isLoggedOut = value
    }
  },
  // 基于state 所派生出来的属性
  getters: {
    isLoggedOut: state => state.isLoggedOut,
    userName: state => state.userInfo.username
  }
}
