<template>
  <div>
    <div class="echarts">
      <div class="header">
        <div class="header-content">
          <img src="../../assets/images/head_bg.png" class="head-img">
          <div class="head-title">医学信息工程学院可视化</div>
          <div class="showTime">{{ currentTime }}</div>
        </div>
      </div>
      <div class="content">
        <div class="leftBox">
          <div class="left-top">
            <h2>柱状图-就业行业</h2>
            <echarts-index></echarts-index>
          </div>
          <div class="left-middle">
            <h2>折线图-运动人数</h2>
            <echarts-direction></echarts-direction>
          </div>
          <div class="left-footer">
            <h2>饼状图-研究方向</h2>
            <echarts-choice></echarts-choice>
          </div>
        </div>
        <div class="echarts-list">
          <div class="echats-img">
            <my-map style="width: 1000px; height: 680px; margin-left: -350px; margin-top: -150px;"></my-map>
            <img src="../../assets/images/map.png" class="image1">
            <img src="../../assets/images/lbx.png" class="image2">
            <img src="../../assets/images/jt.png" class="image3">
          </div>
        </div>
        <div class="rightBox">
          <div class="right-top">
            <h2>柱状图-技能掌握</h2>
            <echart-skill></echart-skill>
          </div>
          <div class="right-middle">
            <h2>折线图-访问数据</h2>
            <echarts-visit></echarts-visit>
          </div>
          <div class="right-footer">
            <h2>折线图-地区分布</h2>
            <echarts-region></echarts-region>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EchartsChoice from './echartsChoice.vue'
import EchartsDirection from './echartsDirection.vue'
import echartsIndex from './echartsIndex.vue'
import EchartSkill from './echartSkill.vue'
import EchartsRegion from './echartsRegion.vue'
import EchartsVisit from './echartsVisit.vue'
import MyMap from './myMap.vue'
export default {
  components: { echartsIndex, EchartsDirection, EchartsChoice, EchartSkill, EchartsRegion, EchartsVisit, MyMap },
  data () {
    return {
      currentTime: '当前时间：2024年11月25-9时1分33秒',
      timer: null
    }
  },
  mounted () {
    this.getTime()
  },
  // 组件销毁前，清空定时器
  beforeDestroy () {
    this.clearTimer()
  },
  methods: {
    getTime () {
      this.updateTime()
      this.timer = setInterval(this.updateTime, 1000) // 每秒更新一次时间
    },
    updateTime () {
      const dt = new Date()
      const y = dt.getFullYear()
      const mt = dt.getMonth() + 1
      const day = dt.getDate()
      // 获取时
      const h = dt.getHours()
      // 获取分
      const m = dt.getMinutes()
      // 获取秒
      const s = dt.getSeconds()
      this.currentTime = `当前时间：${y}年${mt}月${day}-${h}时${m}分${s}秒`
    },
    clearTimer () {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
    }
  }
}
</script>
<style lang="less" scoped>
.echarts {
  background-image: url('../../assets/images/bg.jpg');
  background-repeat: no-repeat; /* 不重复背景图片 */
  background-size: cover; /* 覆盖整个容器 */
  background-position: center; /* 居中对齐 */
  height: 853px; /* 占满父容器的高度 */
  margin: -20px;
  display: flex;
  flex-direction: column; /* 修改为垂直布局 */
  justify-content: flex-start; /* 内容从顶部开始排列 */
  align-items: center;

  .header {
    width: 100%; /* 宽度100%，使其占据整个顶部 */
    text-align: center; /* 文本居中，如果需要的话 */
    padding-top: 20px; /* 上内边距，使头部图片与顶部有一定距离 */
    margin-top: -20px;

    .header-content {
      position: relative; /* 相对定位，使内部的绝对定位元素相对于此容器定位 */
      width: 100%; /* 宽度100%，适应不同屏幕尺寸 */
      max-width: 1460px;
      margin: 0 auto;

      .head-img {
        width: 100%; /* 图片宽度设置为100%，适应不同屏幕尺寸 */
        height: 90px;
      }

      .head-title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -80%);
        color: white;
        font-size: 30px;
        font-weight: bold;
      }

      .showTime {
        position: absolute;
        top: 30px;
        right: 2%;
        line-height: 0.9375rem;
        font-size: 15px;
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }

  .content {
    display: flex;
    flex-grow: 1; /* 让此部分填充剩余空间 */
    justify-content: space-between; /* 内容在左右两侧排列 */
    width: 100%; /* 宽度100%，适应不同屏幕尺寸 */

    .leftBox, .rightBox {
      display: flex;
      flex-direction: column; /* 垂直排列 */
      gap: 10px; /* 盒子之间的间距 */
      width: 27%; /* 左右盒子的宽度 */
      margin-right: 20px; /* 与中间内容的距离 */

      .left-top, .left-middle, .left-footer,
      .right-top, .right-middle, .right-footer {
        height: 230px; /* 每个盒子的高度 */
        width: 355px;
        border: 1px solid rgba(30, 233, 172, 0.17); /* 边框 */
        margin-left: 20px;
        position: relative; /* 使伪元素相对于此元素定位 */

        h2 {
          height: 20px;
          text-align: center;
          color: #fff;
          font-size: 15px;
          font-weight: 400;
        }

          /* 左上角 */
          &::before {
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          border-top: 2px solid #02a6b5;
          border-left: 2px solid #02a6b5;
          top: 0;
          left: 0;
        }

        /* 右上角 */
        &::after {
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          border-top: 2px solid #02a6b5;
          border-right: 2px solid #02a6b5;
          top: 0;
          right: 0;
        }

        /* 左下角 */
        &::before {
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          border-left: 2px solid #02a6b5;
          bottom: 0;
          left: 0;
        }

        /* 右下角 */
        &::after {
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          border-right: 2px solid #02a6b5;
          bottom: 0;
          right: 0;
        }
      }
    }

    .echarts-list {
      flex-grow: 1; /* 让此部分填充剩余空间 */
      display: flex;
      justify-content: center;
      align-items: center;

      .echats-img {
        position: relative;
        width: 300px;
        height: 300px;

        .image1 {
          z-index: 1;
          opacity: 0.5;
          position: absolute;
          top: -48px;
          left: -50px;
          width: 130%;
          height: 130%;
        }

        .image2 {
          z-index: 2;
          position: absolute;
          top: -80px;
          left: -83px;
          width: 150%;
          height: 150%;
          opacity: 0.7;
          animation: rotate 13s linear infinite;
        }

        .image3 {
          z-index: 2;
          position: absolute;
          top: -57px;
          left: -77px;
          width: 149%;
          height: 135%;
          opacity: 0.4;
          animation: rotatejt 13s linear infinite;
        }

        @keyframes rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }

        @keyframes rotatejt {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(-360deg);
          }
        }
      }
    }
  }
}
</style>
